.detail-page {
	max-height: 100%;
	overflow: auto;
}


/* desktop layout */
@media (min-width: 901px) {
}

/* mobile layout */
@media (max-width: 900px) {
	.detail-page h4 {
		margin: 20px 0;
	}
}

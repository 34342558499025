.rd-overview-data-sets-card-mobile {
	padding: 0px;
}

.rd-overview-data-sets-card-mobile .data-set-category-title {
	font-size: 20px;
	font-weight: bold;
}
.rd-overview-data-sets-card-mobile .category-section {
	margin-bottom: 15px;
}
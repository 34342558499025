.get-started-modal-body {

}

.get-started-modal-body .copy-wrapper {
	margin: 0px 0 20px 120px;
	font-size: 16px;
	font-style: italic;
}

.welcome-modal.modal .modal-header {
	padding: 8px;
}

/*body:not(.dark-mode) .get-started-modal-body .logo-wrapper svg g#VIOLERTS_TEXT path {
	fill: #2b2b2b !important;
}*/
.get-started-modal-body .logo-wrapper svg g#VIOLERTS_SIGNAL_ICON path {
	fill: #25d000 !important;
}
.get-started-modal-body .logo-wrapper svg {
	height: 105px;
	width: 100%;
}
.get-started-modal-body .logo-wrapper {
	text-align: center;
}

.get-started-modal-body .address-search {
	margin-left: 0;
}

.get-started-modal-body a.auth-link {
	color: var(--bs-secondary);
}

.get-started-modal-body .backend-search-warpper {
	margin-bottom: 10px;
}
.get-started-modal-body .backend-search-warpper a {
	color: var(--app-black);
/*	color: var(--bs-primary);*/
	font-size: 14px;
}

.get-started-modal-body .backend-search-warpper a:hover {
	text-decoration: underline;
}



/* anti-dark mode styles for welcome modal */
.dark-mode .welcome-modal .modal-content {
	color: var(--app-black);
	background-color: white;
}
.dark-mode .welcome-modal .modal-content path{
	fill: var(--app-black);
}
.dark-mode .welcome-modal button {
	color: var(--bs-secondary) !important;
}
.dark-mode .welcome-modal button:hover {
	color: white !important;
}
.dark-mode .get-started-modal-body .backend-search-warpper a:hover {
	color: var(--bs-primary);
}
.dark-mode .get-started-modal-body .backend-search-warpper a {
	color: var(--bs-primary);
}
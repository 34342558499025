.rd-overview-data-sets-card-vertical {
}

.rd-overview-data-sets-card-vertical .vertical-button-card .vertical-button-card-content {
	border-radius: 0;
	margin-bottom: 10px;
	background-color: transparent;
	margin-left: 55px;
	padding: 0px;
	box-shadow: none;
	overflow: unset;
}

.rd-overview-data-sets-card-vertical .rd-overview-data-sets-card-vertical-content {
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100%;
}
.rd-overview-data-sets-card-vertical .rd-overview-data-sets-card-vertical-list-wrapper,
.rd-overview-data-sets-card-vertical .rd-overview-data-sets-card-vertical-table-wrapper  {
	border-radius: 10px;
	height: 100%;
	overflow: auto;
	padding: 20px;
	padding-bottom: 6px;
	box-shadow: var(--box-shadow);
}

.rd-overview-data-sets-card-vertical .rd-overview-data-sets-card-vertical-list-wrapper,
.rd-overview-data-sets-card-vertical .rd-overview-data-sets-card-vertical-table-wrapper  {
	background-color: var(--border-color);
}

.dark-mode .rd-overview-data-sets-card-vertical .rd-overview-data-sets-card-vertical-list-wrapper,
.dark-mode .rd-overview-data-sets-card-vertical .rd-overview-data-sets-card-vertical-table-wrapper  {
	background-color: var(--dark-mode-bg-2);
}

.rd-overview-data-sets-card-vertical .rd-overview-data-sets-card-vertical-list-wrapper {
	width: 270px;
}

.rd-overview-data-sets-card-vertical .rd-overview-data-sets-card-vertical-table-wrapper {
	flex-grow: 1;
	margin-left: 16px;
	max-width: calc(100% - 286px);
}


.rd-overview-data-sets-card-vertical .vertical-button-group button .vio-badge {
	background-color: var(--bs-primary);
	color: var(--bs-white);
	height: 28px;
	padding-top: 9px;
}

.rd-overview-data-sets-card-vertical .vertical-button-group button.is-selected .vio-badge,
.rd-overview-data-sets-card-vertical .vertical-button-group button:hover .vio-badge {
	background-color: var(--dark-mode-bg-2) !important;
  color: var(--dark-text-color);
}

.rd-overview-data-sets-card-vertical .blank svg {
	/* these aren't the real aspect ratio, the logo doesn't distort */
	/*	height: 110px;*/
	height: 100px;
	width: 100%;
	filter: grayscale(100%) drop-shadow( 3px 3px 2px rgba(0, 0, 0, .3));
}

.rd-overview-data-sets-card-vertical .blank p {
	color: #777777;
}
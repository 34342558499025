.penalties-refunds-card {
	width: 100%;
	background-color: var(--bs-white);
	border-radius: 10px;
	padding: 20px;
}

.penalties-refunds-card .penalties-refunds-table {
	max-width: 900px;
}

.dark-mode .penalties-refunds-card {
	background-color: var(--dark-mode-bg);
}
.dark-mode .penalties-refunds-card .penalties-refunds-card-content {
	background-color: var(--dark-mode-bg-2);
}

.penalties-refunds-card .penalties-refunds-card-content {
	background-color: var(--border-color);
	border-radius: 10px;
	padding: 20px;
}

.penalties-refunds-card .penalties-refunds-card-header {
	margin-bottom: 0px;
	font-size: 16px;
	font-weight: bold;
}

.penalties-refunds-card table thead {
	font-weight: bold;
}

.penalties-refunds-card table {
	margin-bottom: 0;
}

.penalties-refunds-card table td {
	padding: 4px 8px;
}
.penalties-refunds-card table td:not(:first-child) {
	text-align: right;
}

.penalties-refunds-card .amounts-due-totals-row td {
	border-bottom: none;
}



/* desktop layout */
@media (min-width: 1231px) {
	.penalties-refunds-card {
		
	}
}

/* mobile layout */
@media (max-width: 1230px) {
	.penalties-refunds-card {
		padding: 10px;
	}
	.penalties-refunds-card .penalties-refunds-card-content {
		padding: 10px;
	}
}
svg.violerts-logo {

}

/*svg.violerts-logo g#VIOLERTS_SIGNAL_ICON path {
	fill: #28df92 !important;
}*/
svg.violerts-logo g#VIOLERTS_SIGNAL_ICON path:nth-child(1) {
	fill: #28df92 !important;
}
svg.violerts-logo g#VIOLERTS_SIGNAL_ICON path:nth-child(2) {
	fill: #20a670 !important;
}
svg.violerts-logo g#VIOLERTS_SIGNAL_ICON path:nth-child(3) {
	fill: #21815a !important;
}
.table-header-sort-button {
	border: none;
	background: transparent;
	font-weight: bold;
	padding: 0;
	/*border-radius: 3px;*/
}

/*.table-header-sort-button:hover {
	color: #fff;
	background-color: #2c2e3e;
}*/

.header-label{
	color: #444444;
}

.table-header-sort-button:hover:not(.is-unsortable) .header-label {
	border-bottom: 2px solid;
}

.table-header-sort-button.is-unsortable {
	cursor: default;
}

.table-header-sort-button .header-sort-direction-icon {
	margin-left: 6px;
	font-size: 16px;
}

/*.table-header-sort-button .header-label.sort-asc {
	border-bottom: 2px solid;
}
.table-header-sort-button .header-label.sort-desc {
	border-top: 2px solid;
}

.table-header-sort-button:hover .header-label.sort-desc,
.table-header-sort-button:hover .header-label.sort-asc {
	border-top: none;
	border-bottom: none;
}*/
.spinner-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.spinner-container .spinner-wrapper {
	text-align: center;
}

.spinner-container .spinner-border {
	font-size: 16px;
}

.spinner-container .spinner-border.spinner-sm {
	font-size: 12px;
	width: 16px;
	height: 16px;
}
.compliance-dates-collapse .compliance-checklist-header {
	cursor: pointer;
	display: inline-block;
	padding: 5px 10px 5px 0;
}

.compliance-dates-collapse .compliance-checklist-header:hover,
.compliance-dates-collapse .compliance-checklist-header:active {
	text-decoration: underline;
}

.compliance-dates-collapse .compliance-checklist-section {
	margin-bottom: 0;
}

.compliance-dates-collapse .compliance-checklist-section .compliance-checklist-item {
	display: flex;
	margin-left: 10px;
	margin-bottom: 5px;
}

.compliance-dates-collapse .compliance-checklist-section .compliance-checklist-date {
	margin-right: 10px;
	min-width: 30px;
	white-space: nowrap;
}
.app-header-desktop-search {
	align-items: center;
	margin-right: 20px;
}

/* google address */ 
.app-header-desktop-search .google-address-search input {
	height: 34px;
	width: 240px;
}


/* block and lot */ 

.app-header-desktop-search .block-and-lot-search {
	margin-left: 20px;
}

.app-header-desktop-search .block-and-lot-search button, 
.app-header-desktop-search .block-and-lot-search input,
.app-header-desktop-search .block-and-lot-search select {
	height: 34px;
}

.app-header-desktop-search .block-and-lot-search .block-input {
	width: 60px;
}

.app-header-desktop-search .block-and-lot-search .lot-input {
	width: 44px;
}

.app-header-desktop-search .block-and-lot-search select {
	width: 100px;
}

.app-header-desktop-search .block-and-lot-search button {
	padding: 0 10px;
	color: white;
}
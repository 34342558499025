.data-table th {
	white-space: nowrap;
	padding-bottom: 5px;
}

.data-table {
	overflow: auto;
}

.data-table .nothing-found-row {
	text-align: center;
}

.data-table .data-table-buttons-cell {
	width: 66px;
	padding-top: 0.5rem;
	padding-left: 20px;
/*	padding-bottom: 0;*/
/*	vertical-align: middle;*/
}

.data-table .data-table-buttons-wrapper {
	display: flex;
}

.data-table .data-table-buttons-cell i {
	font-size: 17px;
	color: var(--app-black);
}
.dark-mode .data-table .data-table-buttons-cell i {
	color: var(--dark-mode-color);
}
.data-table .data-table-buttons-cell i.bi-cloud-upload,
.data-table .data-table-buttons-cell i.bi-cloud-upload-fill,
.data-table .data-table-buttons-cell i.bi-cloud-arrow-up,
.data-table .data-table-buttons-cell i.bi-cloud-arrow-up-fill {
	font-size: 20px;
}

.data-table .data-table-buttons-cell a:hover i, 
.data-table .data-table-buttons-cell button:hover i {
	color: #009EF7;
}
.data-table .data-table-buttons-cell a,
.data-table .data-table-buttons-cell button {
	margin-right: 12px;
}

.data-table .status-badge.badge.bg-danger {
	background-color: var(--bs-danger) !important;
}
.data-table .status-badge.badge.bg-success {
	background-color: var(--bs-success) !important;
}
.rd-overview-page {
	height: calc(100% - 56px);
	overflow: auto;
	--overview-card-padding: 10px;
}

.dark-mode .rd-overview-page {
	background-color: var(--dark-mode-bg-2);
}

.overview-card {
	padding: 20px 20px 10px 20px;
	background-color: var(--bs-white);
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.dark-mode .overview-card {
	background-color: var(--dark-mode-bg);
}

.dark-mode .overview-card .overview-card-content{
	background-color: var(--dark-mode-bg-2);
}

.overview-card .overview-card-content {
	border-radius: 10px;
	margin-bottom: 10px;
	overflow: auto;
	background-color: var(--border-color);
	padding: 10px;
}

.overview-card.overview-card-small .overview-card-content {
	height: 194px;
	box-shadow: var(--box-shadow);
}

.overview-card-small{
	box-shadow: var(--box-shadow-secondary);
}

/* mobile layout */
@media (max-width: 1230px) {
	.overview-card {
		padding: var(--overview-card-padding);
	}

	.overview-card .overview-card-content {
		padding: 10px;
	}
}


.rd-overview-page .rd-overview-content-wrapper {
	display: flex;
}


.rd-overview-page .overview-row .growable-card-wrapper {
	flex-grow: 1;
}

.rd-overview-page .overview-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.rd-overview-page .overview-row .overview-card-wrapper {
	margin-right: 10px;
	margin-bottom: 10px;
	margin-top: 10px;
}


/* desktop layout */
@media (min-width: 901px) {
	.rd-overview-page .rd-overview-content-wrapper {
		width: 100%;
		display: flex;
	}
	

	.rd-overview-page .overview-row.four-cards-row .overview-card-small {
		width: 25%;
	}
	
	.rd-overview-page .overview-row .overview-card-wrapper:first-child {
		margin-left: 10px;
	}

	.rd-overview-page .overview-row .growable-card-wrapper {
		max-width: calc(100% - 2 * var(--overview-card-padding));
	}
	
	.rd-overview-page .info-card-wrapper {
	  max-width: calc(100% - 480px);
	}
	.rd-overview-page .is-widescreen .overview-left {
		width: 75%;
	}
	.rd-overview-page .overview-left {
		width: 100%;
	}
	.rd-overview-page .is-widescreen .overview-right {
		width: 25%;
	}
	.rd-overview-page .overview-right .overview-card-wrapper {
		width: 100%;
	}


	.rd-overview-page :not(.is-widescreen) .overview-card.overview-card-small {
		margin-right: 10px;
		margin-bottom: 10px;
		margin-top: 10px;
	}

	.rd-overview-page .overview-card.overview-card-small:first-child {
		margin-left: 10px;
	}

	.rd-overview-page .is-widescreen .overview-card.overview-card-small {
		margin-left: 0px;
		margin-right: 0px;
		margin-bottom: 23px;
		margin-top: 0px;
	}


	.rd-overview-page .is-widescreen .overview-card.overview-card-small:first-child {
		margin-left: 0px;
	}
}

/* mobile layout */
@media (max-width: 900px) {
	.rd-overview-page .overview-row {
		flex-direction: column;
	}

	.rd-overview-page .overview-row .overview-card-wrapper {
		margin: 10px 0px;
	}
	
	.rd-overview-page .rd-overview-content-wrapper {
		width: 100%;
	}

	.rd-overview-page .overview-left {
		max-width: 100%;
	}

	.rd-overview-page .rd-building-info {
		flex-direction: column;
	}

	.rd-overview-page .rd-building-info .building-info-col {
		width: 100%;
	}

	.rd-overview-page .overview-card.overview-card-small {
		margin-bottom: 10px;
	}
}
.portfolio-property-card {
/*	background-color: var(--dark-mode-bg-2);*/
	background-color: #fff;
	border-radius: 10px;
	padding: 10px;
	margin: 10px;
	display: flex;
}

.portfolio-property-card .thumb-wrapper img { 
	width: 80px;
	cursor: pointer;
}
.portfolio-property-card .thumb-wrapper { 
	margin-right: 10px;
	display: flex;
	align-items: center;
}

.portfolio-property-card .portfolio-property-card-info { 
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}


.portfolio-property-card .portfolio-property-card-address { 
	font-weight: bold;
}


.property-document-list {
	max-width: 360px;
}

.property-document-list .document-row {
	display: flex;
	/*	justify-content: space-between;*/
	padding: 5px;
	border-bottom: 1px solid var(--border-color);
	align-items: center;
}

.property-document-list .document-row .btn {
	font-size: 12px;
	padding: 2px 6px;
}


.property-document-list .document-row .doc-name {
	width: 250px;
}



.property-document-list .document-row.is-compact {
	padding: 0;
}

.property-document-list .document-row.is-compact .btn {
	/*font-size: 12px;*/
	/*padding: 2px 6px;*/
	padding: 0;
	background: transparent;
	border: none;
}

.property-document-list .document-row.is-compact .btn.btn-primary {
	color: var(--bs-primary);
}
.property-document-list .document-row.is-compact .btn.btn-danger {
	color: var(--bs-danger);
}
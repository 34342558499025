.modal {
	display: block;
	background-color: rgb(0, 0, 0, 0.5);
	z-index: 900;
}

.modal .modal-title,
.modal .modal-footer button {
	font-size: 14px;
}

.modal .modal-title {
	font-weight: bold;
}

.dark-mode .modal .modal-header button.close {
	color: var(--bs-secondary);
}

.modal .modal-header button.close {
	border: none;
	background-color: transparent;
	font-size: 16px;
	color: rgb(33, 37, 41); /* app text color */
	border-radius: .25rem;
	width: 29px;
	height: 29px;
	padding-top: 2px;
}

.modal .modal-header button.close:hover {
	color: white;
	background-color: #5c636a;
}

.modal .modal-footer {
	padding: .75rem;
}

.modal .modal-header {
	padding: .75rem;
}

.modal .modal-header,
.modal .modal-footer,
.modal .modal-body {
	padding-left: 20px;
}

.modal-max {
	max-width: calc(100vw - 20px);
}


/* desktop layout */
@media (min-width: 901px) {
}

/* mobile layout */
@media (max-width: 900px) {
	.modal .modal-body {
		overflow-x: auto;
	}

	.modal .modal-footer {
		/* make space for zendesk button */
		padding-right: 66px;
	}
}

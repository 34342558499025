.signage-card .signage-link img {
	border-radius: 10px;
	width: 120px;
	box-shadow: var(--box-shadow-secondary);
	margin-bottom: 15px;
}

.signage-card.overview-card .overview-card-content {
	overflow-y: hidden;
}

.signage-card .signage-links {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: calc(100% - 20px);
	align-items: center;
	padding: 0px 10px;
	overflow-x: auto;
	overflow-y: hidden;
}

.signage-card .signage-link {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	
	filter: grayscale(1);
	-moz-transition: all .2s ease-in;
	-o-transition: all .2s ease-in;
	-webkit-transition: all .2s ease-in;
	transition: all .2s ease-in;
	margin-right: 20px;
}

.signage-card .signage-link:hover {
	filter: none;
	transform: scale(1.05);
}

.collapse-container .collapse-button {
	background-color: transparent;
	border: none;
	width: 100%;
	/*display: block;*/
	text-align: left;

	display: flex;
	justify-content: space-between;
	color: var(--text-black);
}
.dark-mode .collapse-container .collapse-button {
	color: var(--dark-mode-color);
}

.collapse-container .collapse-button .collapse-icon {
	float: right;
}
.airtable-instructions-page {
	margin: 40px auto;
	margin-bottom: 40px;
	max-width: 800px;
}

.airtable-instructions-page-wrapper {
	max-height: calc(100% - var(--app-header-height));
	overflow: auto;
}


.airtable-instructions-page .card .card-body {
	padding: 2rem;
	padding-top: 2rem;
}

.airtable-instructions-page h1 {
	font-size: 22px;
	font-weight: bold;
	margin-bottom: 20px;
}

.airtable-instructions-page h2 {
	font-size: 16px;
	font-weight: bold;
}

.airtable-instructions-page p {
	margin-bottom: 8px;
}

.airtable-instructions-page .instruction-section {
	margin-bottom: 20px;
}

.airtable-instructions-page .airtable-key-container input {
	padding-left: 10px;
}




/* desktop layout */
@media (min-width: 901px) {
}

/* mobile layout */
@media (max-width: 900px) {
	.airtable-instructions-page .card .card-body {
		padding: 1rem;
		padding-top: 1rem;
	}
	.airtable-instructions-page .card {
		border-radius: 0;
	}
	.airtable-instructions-page {
		margin: 0;
	}

}
.rd-building-info {
	height: 100%;
	overflow: auto;
}

.rd-building-info .rd-building-info-col-container {
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.rd-building-info .building-info-flex-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.rd-building-info .building-info-card-header {
	display: flex;
	justify-content: space-between;
}

.rd-building-info .owner-name-subheader {
	margin-bottom: 0;
	font-size: 14px;
}

.rd-building-info .building-info-card-title {
	margin-bottom: 4px;
}

.rd-building-info .property-link-buttons .btn {
	margin-left: 8px;
	height: 28px;
	font-size: 12px;
}

.rd-building-info .property-link-buttons {
	margin-right: 8px;
	white-space: nowrap;
}

.rd-building-info .property-file-icon-links img {
	width: 36px;
	margin-right: 10px;
}

.rd-building-info .building-info-col:first-child {
	padding-left: 0;
}
.rd-building-info .building-info-col:last-child {
	padding-right: 0;
}

.building-info-value, .building-info-value a {
	color: var(--dark-text-color);
	font-weight: bold;
}
.building-info-value {
	padding-left: 10px;
	text-align: right;
}
.rd-building-info .building-info-item {
	margin-bottom: 5px;
	display: flex;
	justify-content: space-between;
}
.rd-building-info .building-info-item.landmark-toggle-row {
	cursor: pointer;
}
.rd-building-info .building-info-item.landmark-row .building-info-field {
	white-space: nowrap;
}
.rd-building-info .building-info-item.landmark-row .building-info-field i {
	margin-left: 8px;
}


/* desktop layout */
@media (min-width: 901px) {

	.rd-building-info .building-info-col:not(:last-child) {
		border-right: 1px solid var(--bs-border-color);
	}
	.rd-building-info .building-info-item {
		margin-bottom: 20px;
	}
	.rd-building-info .building-info-col {
		width: 32%;
		padding: 0 30px;
	}
}

/* mobile layout */
@media (max-width: 900px) {

	.rd-building-info {
		padding: 0 10px;
	}
}
.vertical-button-card {
	padding: 20px 20px 10px 20px;
	/*	padding: 20px;*/
	/*	background-color: var(--rd-grey);*/
	background-color: var(--bs-white);
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	box-shadow: var(--box-shadow-secondary);

}

.vertical-button-card .vertical-button-card-header {
	display: inline-block;
	border-bottom: 1px solid white;
	margin-bottom: 10px;
	padding-bottom: 5px;
}

.vertical-button-card .vertical-button-card-header .vertical-button-card-title {
	display: inline;
	border-bottom: none;
}

.vertical-button-section{
	position: relative;
	width: 100%;
}

.vertical-button-card .vertical-button-card-content {
	border-radius: 10px;
	margin-bottom: 10px;
	overflow: auto;
	background-color: var(--border-color);
	height: 454px;
	margin-left: 55px;
	padding: 20px;

	box-shadow: var(--box-shadow);
}

.vertical-button-card .vertical-button-card-tabs {
	display: flex;
	flex-wrap: wrap;
}

.vertical-button-card .vertical-button-card-tabs button {
	/*	flex-grow: 1;*/
	/*	flex: 1 1 0;*/
	margin: 5px;
	white-space: nowrap;
}

.vertical-button-card .vertical-button-card-tabs button:not(:first-child) {
	/*	margin-left: 6px;*/
}

.vertical-button-card .vertical-button-card-content .rd-placeholder {
	/*	background-color: var(--rd-black);*/
	/*	color: var(--rd-off-white);*/
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	border-radius: 10px;
	font-size: 20px;
}

.dark-mode .vertical-button-card {
	background-color: var(--dark-mode-bg);
}

.dark-mode .vertical-button-card .vertical-button-card-content{
	background-color: var(--dark-mode-bg-2);
}


/* desktop layout */
@media (min-width: 1231px) {
	.vertical-button-card {
	}
}

/* mobile layout */
@media (max-width: 1230px) {
	.vertical-button-card {
		padding: 10px;
		/*border: 1px red solid;*/
	}

	.vertical-button-card .vertical-button-card-content {
		padding: 10px;
	}

	.vertical-button-card .vertical-button-card-tabs button {
		flex: 1 1 0;
	}

}
.app-body {
	width: 100%;
	/*height: 100%;*/
}

/* desktop layout */
@media (min-width: 901px) {
	.app-body {
		height: calc(100vh - var(--app-header-height) - var(--app-toolbar-height));
	}

	.app-toolbar-and-body {
		/* 56px = app header height */
		/*height: calc(100% - 56px);*/
		height: calc(100% - var(--app-header-height));
	}
	.main-container {
		height: 100%;
		/*height: calc(100% - 111px);*/
	}
}

/* mobile layout */
@media (max-width: 900px) {
	.app-body {
		height: auto;
		margin-bottom: 80px;
		/*margin-top: var(--app-toolbar-height);*/
	}
	.app-toolbar-and-body {
		/* 56px = app header height */
		/*height: calc(100% - 56px);*/
		height: calc(100% - var(--app-header-height));
		/*height: auto;*/
		overflow: auto;
	}
	.main-container {
		height: auto;
		/*height: calc(100% - 111px);*/
	}
}

.main-container {
	padding: 0;
	/*width: 100%;*/
	margin-right: 0;
	/*position: absolute;*/
	/*top: 56px;*/
	/*height: calc(100% - 56px);*/
	/*top: 111px;*/
	/*height: calc(100% - 111px);*/
}

.main-container.has-sidebar {
	width: calc(100% - 280px);
	margin-left: 280px;
}

.property-not-found-page {
	max-height: calc(100% - var(--app-header-height));
	overflow: auto;
	padding-bottom: 40px;
}

.property-not-found-page-content {
	margin: auto;
	margin-top: 60px;
	max-width: 400px;
	margin-bottom: 60px;
}


.property-not-found-page-content h1 {
	margin-bottom: 20px;
	font-size: 55px;
}

.property-not-found-page h2 {
	font-size: 30px;
	margin-bottom: 20px;
}

.property-not-found-page p {
	font-size: 18px;
	margin-bottom: 30px;
}


.property-not-found-page .contact-info {
	margin-top: 80px;
	text-align: center;
}

.property-not-found-page div {
	margin-bottom: 10px;
}
.google-address-search i,
.google-address-search select,
.google-address-search input {
	font-size: 14px;
}

.google-address-search input.header-hint {
	/*box-shadow:  0px 0px 4px 4px var(--bs-primary);*/
	border-color: var(--bs-primary);
}

.sign-up-cta-wrapper {
	display: flex;
	align-items: center;
	height: 100%;
	flex-direction: column;
	justify-content: center;
	padding-top: 30px;
}

.sign-up-cta-wrapper div {
	margin-bottom: 20px;
}
.rd-representatives {

}

.rd-representatives .rd-representatives-title {
	font-size: 20px;
	margin: auto 0;
}

.rd-representatives .representatives-controls {
	display: flex;
	justify-content: space-between;
}

.rd-representatives table {
	max-width: 1000px;
}

.rd-representatives .representatives-controls {
/*	display: flex;
	justify-content: space-between;*/
}

.rd-representatives .representatives-controls a {
	color: var(--rd-white);
}

.rd-representatives .representatives-controls a.export-link {
	color: var(--rd-white);
	font-size: 26px;
	margin-left: 10px;
}

.rd-representatives .representatives-controls a.biz-entities-link {
	display: flex;
	font-size: 16px;
	align-items: center;
	white-space: nowrap;
}


.rd-representatives .representatives-controls .export-link.disabled {
	color: var(--bs-secondary);
	font-size: 26px;
	margin-left: 10px;
}


.rd-representatives .representatives-controls select {
	margin-left: 10px;
	width: 130px;
}


/* desktop layout */
@media (min-width: 901px) {

}

/* mobile layout */
@media (max-width: 900px) {
	.rd-representatives .representatives-controls {
		flex-direction: column;
	}
}
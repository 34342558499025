.data-sets-list {

}

.data-sets-list button.btn-hyperlink {
	padding: 6px 0px;
}

.data-sets-list .data-set-link-wrapper.is-selected button.btn-hyperlink {
	font-weight: bold;
}


.dark-mode .data-sets-list button.btn-hyperlink {
	white-space: nowrap;
	color: #777777;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

.data-sets-list button.btn-hyperlink:hover {
	text-decoration: none;
	transform: scale(1.05);
}
.dataSources {
    background-color: #2c2e3f;
}

.ds-container{
    display: flex;
}

.ds-container-left, .ds-container-right{
    flex: 50%;
    position: relative;
}

.dataSources h1 {
    font-weight: bold;
    font-size: 3vmax;
    width: 100%;
}

.dataSources p{
    font-size: 1vmax;
    color: white;
    line-height: 2;
}

.ds-container-right{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.marquee-wrapper{
    display: flex;
    justify-content: space-evenly;
    position: relative;
    height: 95%;
    width: 100%;
    overflow: clip;
}
.marquee-wrapper:before{
    position: absolute;
    width: 100%;
    height: 5%;
    background: linear-gradient(180deg, rgba(44, 46, 63, 1) 40%, rgb(44, 46, 63, 0) 100%);
    top: 0;
    content: "";
    z-index: 100;
}

.marquee-wrapper:after{
    position: absolute;
    width: 100%;
    height: 5%;
    background: linear-gradient(0deg, rgba(44, 46, 63, 1) 40%, rgb(44, 46, 63, 0) 100%);
    bottom: 0;
    content: "";
    z-index: 100;
}


.slide-1, .slide-2, .slide-3 {
    width: 8vmax;
}

.slide-1 div, .slide-2 div, .slide-3 div{
    background-color: #fff;
    margin: 20% 0;
    border-radius: 10px;
    width: 100%;
    height: 8vmax;
    box-shadow: var(--box-shadow-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.slide-1 div img, .slide-2 div img, .slide-3 div img{
    width: 100%;
    padding: 20%;
}

.slide-1{
    margin-top: -5%;
    animation: slide-1-move ease-in-out forwards;
    animation-timeline: view(block 20% 10%);
}

@keyframes slide-1-move {
    from{transform: translateY(-30%)}
    to{transform: translateY(30%)}
}

.slide-2{
    margin-top: 5%;
    animation: slide-2-move ease-in-out;
    animation-timeline: view();
}

@keyframes slide-2-move {
    from{transform: translateY(10%)}
    to{transform: translateY(-10%)}
}

.slide-3{
    margin-top: -10%;
    animation: slide-3-move ease-in-out;
    animation-timeline: view();
}

@keyframes slide-3-move {
    from{transform: translateY(-5%)}
    to{transform: translateY(30%)}
}

@media only screen and (max-width: 950px) {

    .ds-container{
        flex-direction: column;
    }

    .marquee-wrapper {
        transform: rotate(90deg);
        height: 110vw;
    }

    .slide-1, .slide-2, .slide-3 {
        height: 100%;
    }

    .slide-1 div, .slide-2 div, .slide-3 div{
        height: 10vmax;
        width: 10vmax;
        margin: 70% 0;
    }

    .slide-1 div img, .slide-2 div img, .slide-3 div img {
        transform: rotate(-90deg);
    }
}
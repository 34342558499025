.calendar-wrapper {
	/*height: fit-content;*/
	/*height: 100%;*/
	background-color: var(--bs-white);
	padding: 2%;
	border-radius: var(--border-radius);
	/*margin-bottom: 10px;*/
	margin-right: 10px;

}
.calendar-wrapper, .dates-list-wrapper {
	box-shadow: var(--box-shadow-secondary);
}

.rd-compliance-dates-header {
	display: flex;
	flex-direction: row;
}
.dates-list-wrapper {
	/*border: 1px blue solid;*/
	background-color: var(--bs-white);
	border-radius: var(--border-radius);
	padding-top: 10px;
	margin-left: 10px;
}
.compliance-dates-content {
	/*max-height: 500px;*/
}
.rd-compliance-dates {
	/*max-height: 300px;*/
	/*border: 1px red solid;*/
}

.rd-compliance-dates .rd-compliance-dates-title {
	font-size: 20px;
}


.rd-compliance-dates .fc-toolbar-title {
	font-size: 16px;
}

.fc-day-today {
	background: var(--dark-mode-bg-2) !important;
}
.fc .fc-button-primary { color: #FFFFFF; background-color: var(--bs-primary) !important; border-color: #FFFFFF !important; }

.rd-compliance-dates .fc .fc-toolbar.fc-header-toolbar {
	margin-bottom: 10px;
}


.rd-compliance-dates .dates-list-wrapper h3 {
	font-size: 18px;
	padding-top: 10px;
}

.rd-compliance-dates .rd-compliance-dates-header a {
/*	color: var(--text-black);*/
	font-size: 20px;
	margin-left: 15px;
}


.dark-mode .rd-compliance-dates .rd-compliance-dates-header a {
/*	color: white;*/
}

/* desktop layout */
@media (min-width: 901px) {

	.rd-compliance-dates .calendar-wrapper {
		width: 50%;
	}
	.rd-compliance-dates .dates-list-wrapper {
		width: 50%;
		padding: 5px 20px;
	}
}

/* mobile layout */
@media (max-width: 900px) {

	.rd-compliance-dates .compliance-dates-content {
		flex-direction: column;
	}

	.rd-compliance-dates .dates-list-wrapper {
		margin-top: 20px;
	}
	
}
.map-card {
	width: 440px;

	max-width: 100%;
	box-shadow: var(--box-shadow-secondary);
}

.map-card .map-icon {
	border-radius: 10px;
	filter: grayscale(1);

	margin-bottom: 5px;
	-moz-transition: all .2s ease-in;
	-o-transition: all .2s ease-in;
	-webkit-transition: all .2s ease-in;
	transition: all .2s ease-in;
}

.map-card .map-icon:hover {
	filter: none;
	transform: scale(1.05);
}

.map-card.overview-card .overview-card-content {
	position: relative;

	padding: 0;
	max-width: 100%;
	overflow: hidden;


	box-shadow: var(--box-shadow);
}

.map-widget {
	position: relative;
}

.map-widget .map-error-message {
	padding-top: 40%;
	font-size: 16px;
	text-align: center;
}

.map-widget .map-overlay-buttons {
	position: absolute;
	z-index: 1001;
	right: 10px;
	top: 10px;
	display: flex;
	flex-direction: column;
}

.map-widget .map-icons {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	padding: 20px;
	gap: 50px;
}

.map-widget .map-icons button {
	padding: 0;
	border: none;
	background: transparent;

	font-weight: bold;
}

.map-widget .map-icons button div {
	color: var(--dark-text-color);
}


.map-widget .map-icons button img {
	width: 85px;
	height: 85px;

	box-shadow: var(--box-shadow);
}

.dark-mode .map-widget .map-icons button.is-disabled,
.map-widget .map-icons button.is-disabled {
	color: var(--bs-secondary);
}
.map-widget .map-icons button.is-disabled img {
	filter: grayscale(1);
}

.map-widget .map-overlay-buttons button {
	border: none;
	font-size: 16px;
	color: white;
	background-color: var(--dark-mode-bg);
	border-radius: 100%;
	width: 30px;
	height: 30px;
	padding-top: 2px;
}

.dark-mode .map-widget .map-overlay-buttons button:hover,
.map-widget .map-overlay-buttons button:hover {
	color: var(--bs-primary);
}


/* desktop layout */
@media (min-width: 901px) {
	.map-card {
		height: 494px;
		margin-left: 10px;

	}

	.map-card.overview-card .overview-card-content {
		height: 480px;
	}
}

/* mobile layout */
@media (max-width: 900px) {
	.map-card .image-overlay-buttons button,
	.map-card .map-overlay-buttons button {
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.map-card .map-widget .map-icons {
		padding: 15px;
		gap: 20px;
	}

}

.map-overlay-buttons button i, .image-overlay-buttons button i{
	color: var(--bs-primary);
}
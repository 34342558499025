.toggle-switch-wrapper {
	margin-bottom: 10px;
}

.toggle-switch-text {
	margin-left: 10px;
	vertical-align: super;
	cursor: pointer;
	user-select: none;
}

.toggle-switch-label {
	display: flex;
}

.toggle-switch-wrapper .react-toggle--checked .react-toggle-track {
	background-color: var(--bs-success);
}

.toggle-switch-wrapper .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
	background-color: var(--bs-active-success);
}
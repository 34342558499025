.account-settings {
	max-height: calc(100% - var(--app-header-height));
	overflow: auto;
}

.account-settings-content {
	margin: 40px;
}

.account-settings .card {
	margin-bottom: 20px;
}

.account-settings .card-body {
	position: relative;
}

.account-settings .card-body .spinner-container {
	background: rgba(255, 255, 255, 0.5);
	position: absolute;
	width: calc(100% - 2rem);
	height: calc(100% - 2rem);
	z-index: 1000;
}

.dark-mode .account-settings .card-body .spinner-container {
	background: rgba(32, 32, 32, 0.97);
}


.account-settings .card-wrapper {
	max-width: 600px;
	margin: 0 auto;
}

.account-settings .api-key-input {
	padding-left: 10px;
	border: 1px solid #ced4da;
	background-color: #e9ecef;
	border-radius: .25rem;
}


.account-settings-content h2 {
	margin-bottom: 40px;
}


/* desktop layout */
@media (min-width: 901px) {
}

/* mobile layout */
@media (max-width: 900px) {
	.account-settings-content {
		margin: 20px 10px;
	}

	.account-settings-content h2 {
		margin-bottom: 20px;
	}
}
.account-dropdown .vio-dropdown-button {
	padding-bottom: 0;
	padding-top: 0;

}

.account-dropdown .vio-dropdown-button:hover,
.account-dropdown .vio-dropdown-button:active {
	background: transparent;
	color: var(--bs-primary);
}

.account-dropdown .vio-dropdown-button-icon i {
	font-size: 22px;
}


.account-dropdown .vio-dropdown-menu-item {
	color: var(--text-black);
}



/* desktop layout */
@media (min-width: 901px) {
	.account-dropdown {
		/*margin-right: 15px;*/
	}
}

/* mobile layout */
@media (max-width: 900px) {
	
}
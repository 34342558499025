.checkout-page,
.cart-page {
	max-height: 100%;
	overflow-y: auto;
	padding-bottom: 80px;
}

.cart-page .cart-item-row {
	display: flex;
	padding: 10px;
	width: 100%;
	justify-content: space-between;
	border: 1px solid var(--border-color);
	border-radius: var(--border-radius);
	max-width: 500px;
	margin: 10px 0;
	align-items: center;
}

/*.cart-page .cart-items-by-address {
	border: 1px solid var(--border-color);
	border-radius: var(--border-radius);
	padding: 15px;
}
*/

.cart-page .cart-items-by-address .address-header {
	font-weight: bold;
	font-size: 16px;
}

.checkout-page .cart-item-row {
	padding: 15px;
	border: 1px solid var(--border-color);
	border-radius: var(--border-radius);
}


/* checkout  */
.checkout-page .form-group {
	margin-bottom: 20px;
}

.checkout-page .email-input.invalid {
	border-color: var(--bs-danger);
}

.checkout-page .email-input.valid {
	border-color: var(--bs-success);
}
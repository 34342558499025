.dark-mode .rd-toolbar-icons a:not(.btn) {
	color: var(--dark-mode-color);
}
.dark-mode .rd-toolbar-icons .toolbar-buttons a:not(.btn):hover {
	color: var(--bs-active-primary);
}
.dark-mode .rd-toolbar-icons .toolbar-current-property a:not(.btn):hover {
	border-bottom: 2px solid var(--dark-mode-color);
}

.rd-toolbar-icons .toolbar-buttons {
	white-space: nowrap;
}
.rd-toolbar-icons .toolbar-buttons i {
	font-size: 24px;
}

.rd-toolbar-icons .toolbar-button {
	color: var(--text-black);
}
.dark-mode .rd-toolbar-icons .toolbar-button {
	color: var(--dark-mode-color);
}

.rd-toolbar-icons .toolbar-icon-wrapper .bi-bell-fill:hover,
.dark-mode .rd-toolbar-icons .toolbar-button:hover {
	color: var(--bs-active-primary);
}

.rd-toolbar-icons a:not(.btn) {
	color: var(--text-black);
	font-size: 16px;
	font-weight: bold;
}

.rd-toolbar-icons a.btn.toolbar-button {
	font-weight: normal;
	font-size: 14px;
}

.rd-toolbar-icons a:not(.btn):hover {
	text-decoration: none;
	border-bottom: 2px solid var(--text-black);
}

.rd-toolbar-icons .toolbar-icon-wrapper a:hover {
	border-bottom: none;
	/*color: #0d6efd;*/
}

.rd-toolbar-icons .toolbar-icon-wrapper .bi-bell-fill {
	color: var(--bs-danger);
}

.rd-toolbar-icons .fancy-hourglass {
	position: relative;
}
.rd-toolbar-icons .fancy-hourglass.in-progress .bi-hourglass-split {
	color: var(--bs-gray-500);
}
.rd-toolbar-icons .fancy-hourglass .spinner-container {
	position: absolute;
	top: -10px;
	left: 14px;
}
.rd-toolbar-icons .fancy-hourglass .check {
	position: absolute;
	color: var(--bs-success);
	top: -10px;
	left: 15px;
	font-size: 15px;
}

.rd-toolbar-icons .toolbar-buttons.dummy-buttons i:not(.bi-door-open-fill) {
	color: var(--bs-gray-600);
}




/* desktop layout */
@media (min-width: 901px) {
}

/* mobile layout */
@media (max-width: 900px) {
}

.hero {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    height: fit-content;
    background: #2c2e3e;
    color: white;
}

.hero .logo-beta {
	max-height: 180px;
	max-width: 96%;
	margin: 0 auto;
	display: block;
	margin-bottom: 16px;
}

/* Left Side */

.hero-left {
    flex: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Right Side */

.hero-right {
    flex: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;

}

.hero-img {
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
}

.active-img {
    opacity: 1;
}

.hero-carousel {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: 2%;

    position: relative;
    height: 100%;

}

.hero-carousel img {
    width: 100%;
    background-size: cover;
    background-position: center;
    -webkit-transition: all 1s ease-in;
    -moz-transition: all 1s ease-in;
    transition: all 1s ease-in;

    position: absolute;
}

.carousel-nav {
    display: flex;
    flex-direction: row;
    position: relative;

    margin: 1% 15% 0 25%;
    justify-content: space-between;

    z-index: 100;
    font-size: 1.25vmax;

}

.carousel-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    width: 50%;
    bottom: 4%;
    right: 35%;
}

.carousel-text h1 {
    width: 100%;
    text-align: center;
}
.carousel-text p {
    font-size: 1vmax;
    text-align: center;
}

.invisible {
    display: none;
}

.carousel-nav a{
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.carousel-nav a:hover{
    cursor: pointer;
    transform: scale(1.05);
}


/* css from Chris for search bar*/
.rd-landing-page-search {
    width: 90%;
}

.rd-landing-page-search svg {
    width: 100%;
    height: 100%;
}

.tagline {
    font-size: 1.25vmax;
    margin-bottom: 45px;
    text-align: center;
}


@media screen and (max-width: 950px) {
    .hero {
        flex-direction: column;
    }

    .tagline {
        font-size: 2vmax;
    }
    .hero-left {
        margin-top: 10%;
    }
    .hero-right {
        flex-direction: column-reverse;
        padding: 5%;
    }

    .carousel-nav {
        margin: 2% 0;
        font-size: 2.5vmax;
        bottom: 0;
        right: 0;
        justify-content: space-evenly;
    }
    .carousel-text {
        align-items: flex-start;
        position: absolute;
        width: 55%;
        bottom: 0%;
        right: 35%;
    }


    .hero-carousel {
        margin: 2%;
        height: 100%;
        justify-content: center;
    }

    .hero-carousel img {
        /*width: 50%;*/
    }



}
/*.hero-carousel {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    max-width: 100%;*/
/*    margin: 2%;*/

/*    position: relative;*/
/*    height: 100%;*/

/*}*/
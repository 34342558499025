.toolbar {
	z-index: 100;
	border-bottom: 1px solid var(--border-color);
	height: var(--app-toolbar-height);
	padding-left: 20px;
	padding-right: 20px;
	/*align-items: center;*/

	display: flex;
	justify-content: space-between;
	/*align-items: center;*/
}

.dark-mode .toolbar {
	background-color: var(--dark-mode-bg) !important;
	
}

.dark-mode .toolbar a:not(.btn) {
	color: var(--dark-mode-color);
}
.dark-mode .toolbar .toolbar-buttons a:not(.btn):hover {
	color: var(--bs-active-primary);
}
.dark-mode .toolbar .toolbar-current-property a:not(.btn):hover {
	border-bottom: 2px solid var(--dark-mode-color);
}

.toolbar .toolbar-left{
	align-items: center;

	display: flex;
	/*align-items: center;*/
}


.toolbar .toolbar-buttons {
	white-space: nowrap;
}
.toolbar .toolbar-buttons i {
	font-size: 24px;
}

.toolbar .toolbar-button {
	color: var(--text-black);
}
.dark-mode .toolbar .toolbar-button {
	color: var(--dark-mode-color);
}

.toolbar a:not(.btn) {
	color: var(--text-black);
	font-size: 16px;
	font-weight: bold;
}

.toolbar a.btn.toolbar-button {
	font-weight: normal;
	font-size: 14px;
}

.toolbar a:not(.btn):hover {
	text-decoration: none;
	border-bottom: 2px solid var(--text-black);
}

/*.toolbar.toolbar-mobile {
	width: 100%;
	position: fixed;
	top: var(--app-header-height);
}*/

/*.toolbar .toolbar-left-content {
	display: flex;
	align-items: center;
}*/

.toolbar .toolbar-icon-wrapper a:hover {
	border-bottom: none;
	/*color: #0d6efd;*/
}

.toolbar .toolbar-icon-wrapper .bi-bell-fill {
	color: var(--bs-danger);
}

.toolbar .fancy-hourglass {
	position: relative;
}
.toolbar .fancy-hourglass.in-progress .bi-hourglass-split {
	color: var(--bs-gray-500);
}
.toolbar .fancy-hourglass .spinner-container {
	position: absolute;
	top: -10px;
	left: 14px;
}
.toolbar .fancy-hourglass .check {
	position: absolute;
	color: var(--bs-success);
	top: -10px;
	left: 15px;
	font-size: 15px;
}

.toolbar .toolbar-buttons.dummy-buttons i:not(.bi-door-open-fill) {
	color: var(--bs-gray-600);
}




/* desktop layout */
@media (min-width: 901px) {
	.toolbar {
		/*justify-content: left;*/
/*		justify-content: space-between;*/
/*		justify-content: right;*/
		padding-right: 30px;
	}	
	.toolbar .toolbar-left {
/*		justify-content: left;*/
		justify-content: space-between;
		width: 100%;
	}	
	.toolbar .toolbar-right {
		padding-right: 10px;
	}	

	.toolbar .toolbar-current-property.dummy-property {
		font-size: 16px;
		font-weight: bold;
	}
}

/* mobile layout */
@media (max-width: 900px) {
	.toolbar {
		justify-content: space-between;
		/*flex-direction: column;*/
		padding: 0 16px 0 12px;
	}	
	.toolbar .toolbar-left {
		justify-content: space-between;
		width: 100%;
		/*padding: 0 20px;*/
	}	

	.toolbar .toolbar-current-property {
		font-size: 13px;
	}

	.toolbar .toolbar-current-property .toolbar-street-address, 
	.toolbar .toolbar-current-property .toolbar-city-state {
		line-height: 1;
	}

	.toolbar .toolbar-current-property .mobile-address {
		display: flex;
		align-items: center;
	}

	.toolbar .toolbar-current-property .mobile-address i {
		margin-left: 5px;
		/*font-size: 20px;*/
	}

	.toolbar .toolbar-current-property .mobile-address .menu-button {
		margin-right: 8px;
	}
	.toolbar .toolbar-current-property .mobile-address .menu-button.is-active {
		background-color: var(--bs-active-primary);
	}
	.toolbar .toolbar-current-property .mobile-address .menu-button i {
		margin-left: 0;
	}

	.toolbar .toolbar-city-state {
		font-weight: normal;
		font-size: 12px;
	}
}

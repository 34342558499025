.vio-badge {
	margin-left: 5px;
	font-weight: normal;
	border-radius: 10px;
	/*width: 30px;*/
	vertical-align: middle;
	/*height: 16px;*/
	height: 19px;
	font-size: 12px;
}

.vio-badge.bg-secondary {
	color: var(--app-black);
}
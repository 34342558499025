.btn.btn-link.data-table-link-button {
	margin: 0;
	padding: 0;
	font-size: 14px;
	line-height: 1;
	text-decoration: none;
}

.btn.btn-link.data-table-link-button:hover {
	text-decoration: underline;
}
.overview-dropdown-links {
	display: inline;
	margin-right: 8px;
}

.overview-dropdown-links .overview-dropdown-links-button {

}

.overview-dropdown-links .overview-dropdown-links-menu {
	width: 375px;
	padding: 10px 20px;
	max-height: 80vh;
	overflow-y: auto;
	border-radius: 10px;
}

.overview-dropdown-links .overview-dropdown-links-menu a {
	display: inline-block;
	padding: 2px 0;
}

.overview-dropdown-links .external-icons {
	margin-bottom: 10px;
}
.overview-dropdown-links .external-icons .acris-wrapper, 
.overview-dropdown-links .external-icons a {
	border-bottom: 2px solid transparent;
}
.overview-dropdown-links .external-icons .acris-wrapper:hover,
.overview-dropdown-links .external-icons a:hover {
	border-bottom: 2px solid var(--bs-primary);
}
.overview-dropdown-links .external-icons .acris-wrapper button:hover {
	text-decoration: none;
}
.overview-dropdown-links .external-icons img {
	height: 16px;
	margin: 0 5px;
}
.overview-dropdown-links .external-icons img.img-dof {
	height: 16px;
	margin: 0 2px;
}
.overview-dropdown-links .external-icons img:hover {
/*	border-bottom: 2px solid white;*/
}




/* desktop layout */
@media (min-width: 901px) {
}

/* mobile layout */
@media (max-width: 900px) {
	.overview-dropdown-links .overview-dropdown-links-menu {
		max-height: 70vh;
		max-width: 70vw;
	}
}

.footer {
    padding: 2vh 1vmin 8vh;
    background: var(--dark-mode-bg);
    background: #262634;

    /*border: 1px red solid;*/
}

/* Footer Top Section */

.footer-top {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    /*border: 1px blue solid;*/
    padding: .5% 0;
}
.nas-icons {
    /*border: white 1px solid;*/
    display: none;
}

.company-icons {

    /*border: 1px green solid;*/
}

.company-icons a img, .nas-icons a img, .footer-bot a img, .bot-left-links a {
    height: 30px;
    margin: 5px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.company-icons a img:hover, .nas-icons a img:hover, .footer-bot a img:hover, .bot-left-links a:hover {
    filter: brightness(75%);
    cursor: pointer;
}
.bot-left-links a:hover {
    color: white;
    text-decoration: none;
}


/* Footer Bottom Section */

.footer-bot {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /*margin-top: 1%;*/
    padding: .5% 0;

    /*border: 1px green solid;*/
}
.bot-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    /*border: red 1px solid;*/
    /*flex: 33.33%;*/
    width: fit-content;
}

.bot-left-icons {
    width: auto;
}
.bot-left-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /*border: yellow 1px solid;*/
    /*flex: 33.33%;*/
    width: fit-content;

}

.bot-left-links a {
    display: flex;
    justify-content: center;
    color: white;
    margin: 0 0%;
    /*width: fit-content;*/
}
.bot-left-links p, .bot-right p {
    font-size: .75vmax;
    color: #444;
    margin: auto;
}

.bot-left-links p{
    margin-left: 10px;
}


.bot-right{
    display: flex;
    /*align-items: end;*/
    justify-content: flex-end;
    /*border: 1px white solid;*/
    /*flex: 33.33%;*/
    width: fit-content;
}

@media screen and (max-width: 950px) {
    .footer-top {
        flex-direction: column;
        align-items: center;
    }
    .footer-bot {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .bot-left {
        flex-direction: column;
        justify-content: center;
        width: 100%;

    }
    .bot-left-links {
        /*border: 1px red solid;*/
        width: 100%;
    }
    .bot-left-links a {
        min-width: unset;
        width: 50%;
        margin: 0 0
    }
    .bot-left-links p{
        font-size: 1rem;
    }
    .bot-right{
        justify-content: center;
        align-items: center;
    }
    .bot-right p {
        font-size: .8rem;
    }

}
.calendar-page {
	max-height: 100%;
	overflow: auto;
	padding: 20px;
}



/* desktop layout */
@media (min-width: 901px) {
}

/* mobile layout */
@media (max-width: 900px) {
	.calendar-page {
		padding: 10px;
	}
}

.mobile-property-nav {
	padding-top: 10px;
	padding-bottom: 30px;
	max-height: 100%;
	overflow-y: auto;
}
.mobile-property-nav ul {
	list-style: none;
	padding-left: 0;
}

.mobile-property-nav .data-set-nav-button {
	padding-left: 20px;
}

.mobile-property-nav .collapse-button {
	padding-left: 20px;
}

.mobile-property-nav .collapse-button:hover {
	background-color: transparent;
	color: var(--app-black);
}

.mobile-property-nav .collapse-button:active {
	background-color: #2c2e3e;
	color: #fff;
}

.mobile-property-nav a.data-set-nav-button .dataset-title,
.mobile-property-nav button.data-set-nav-button .dataset-title {
	max-width: 100%;
}

.mobile-property-nav .collapse-container a.data-set-nav-button,
.mobile-property-nav .collapse-container button.data-set-nav-button {
	padding-left: 30px;
}

.mobile-property-nav li.divider {
	border-color: #ccd4dc;
}
.backend-search .backend-search-controls {
	display: flex;
}

.backend-search .backend-search-controls select,
.backend-search .backend-search-controls input {
	max-width: 300px;
}

.backend-search .backend-search-controls select {
	margin-right: 20px;
}

.backend-search-nothing-found {
	text-align: center;
}

.backend-search-results-table-wrapper {
	overflow: auto;
}

.backend-search .backend-search-desktop {
	display: flex;
}

.backend-search .backend-search-desktop .data-set-select {
	width: 300px;
	margin-right: 10px;
}

.backend-search .backend-search-desktop .backend-search-right-content {
	width: calc(100% - 300px);
}


.backend-search .backend-search-desktop .backend-search-controls input.search-input {
	max-width: 500px;
	width: 500px;
}

.backend-search .backend-search-desktop .data-set-select .data-set-radio-option input {
	vertical-align: middle;
	cursor: pointer;
	display: none;
}

.backend-search .backend-search-desktop .data-set-select .data-set-radio-option {
	display: block;
	padding: 5px 10px;
	cursor: pointer;
	background-color: ;
}


.backend-search .backend-search-desktop .data-set-select h2 {
	margin-left: 14px;
}
.backend-search .backend-search-desktop h2 {
	font-weight: bold;
}

.backend-search .backend-search-desktop .data-set-select .data-set-radio-option:hover,
.backend-search .backend-search-desktop .data-set-select .data-set-radio-option.is-selected {
	background-color: #009EF7;
	color: white;
}

.backend-search .backend-search-desktop .data-set-select .data-set-radio-option .label-text {
	margin-left: 10px;
	display: flex;
}

.backend-search .card-body.desktop-layout {
	padding-left: 0;
}

.backend-search .page-buttons-container {
	display: flex;
}
.backend-search .page-buttons-container input {
	margin: 0 10px;
}

.backend-search .data-set-options-desktop {
	max-height: calc(100vh - 156px);
	overflow-y: scroll;
	border: 1px solid var(--border-color);
	border-radius: var(--border-radius);
	margin: 0 10px;
}

/* desktop layout */
@media (min-width: 901px) {

	.backend-search .backend-search-controls .input-group {
		width: auto;
	}

	.backend-search .page-buttons-container {
		margin-left: 20px;
	}
}

/* mobile layout */
@media (max-width: 900px) {
	.backend-search .page-buttons-container {
		margin-top: 10px;
	}

	.backend-search .backend-search-controls {
		flex-direction: column;
	}
	
	.backend-search .backend-search-controls select {
		margin-bottom: 10px;
	}

	.backend-search .backend-search-controls select,
	.backend-search .backend-search-controls input {
		max-width: 100%;
	}

}

.vio-dropdown-container { 
	position: relative;
}

.vio-dropdown-button i.bi-chevron-up, 
.vio-dropdown-button i.bi-chevron-down {
	margin-left: 8px;
}

.vio-dropdown-button:not(.no-text) .vio-dropdown-button-icon {
	margin-right: 8px;
}

.vio-dropdown-menu {
	z-index: 1000;
	width: 220px;
	position: absolute;
	background-color: white;
	border-radius: 3px;
	right:  0;
	margin-top: 5px;
	padding: 10px 0;
	border: 1px solid var(--border-color);
}

.dark-mode .vio-dropdown-menu {
	background-color: var(--dark-mode-bg);
	color: var(--dark-mode-color);
}

.vio-dropdown-menu-item button span {
	color: var(--dark-text-color);
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

.vio-dropdown-menu-item:hover button span {
	color: white;
}

.vio-dropdown-menu-item button {
	width: 100%;
	background-color: transparent;
	border: none;
	text-align: left;
	height: 36px;
	padding: 5px 20px;
	-moz-transition: all .2s ease-in;
	-o-transition: all .2s ease-in;
	-webkit-transition: all .2s ease-in;
	transition: all .2s ease-in;
}

.vio-dropdown-menu-item .vio-dropdown-menu-item-icon {
	margin-right: 10px;
}

.vio-dropdown-menu-item button:hover {
	/*background-color: #2c2e3e;*/
	background-color: var(--bs-primary);
	color: white;
}
.block-and-lot-search i,
.block-and-lot-search select,
.block-and-lot-search input {
	font-size: 14px;
}

.block-and-lot-search select,
.block-and-lot-search input {
	padding: .375rem .325rem .375rem .75rem;
	padding-left: 10px;
}

.block-and-lot-search button {
	padding-left: 10px;
	padding-right: 10px;
}

.block-and-lot-search .borough-select {
	width: 55px;
	background-image: none;
	font-size: 14px;
}

.block-and-lot-search .borough-select:focus {
	box-shadow: none;
	outline: none;
	border-color: #86b7fe;
} 

.block-and-lot-search button:hover,
.block-and-lot-search button:focus,
.block-and-lot-search button:active {
	background-color: var(--bs-active-primary);
	border-color: var(--bs-active-primary);
} 
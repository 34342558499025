.nothing-found-card-content {
	display: flex;
	align-items: center;
	height: 100%;
	flex-direction: column;
	justify-content: center;
}

.nothing-found-card-content div {
	margin-bottom: 20px;
	text-align: center;
}
.rd-overview-data-table-content {
	height: 100%;	
}


.rd-overview-data-table-content .table-paginator-wrapper {
	height: calc(100% - 34px);	
	overflow: auto;
}


.rd-overview-data-table-content .table-wrapper {
/*	max-height: calc(100% - 45px);	*/
	height: auto;
}

.rd-overview-data-table-content .spinner-container {
	position: relative;
	background-color: transparent;
}

.rd-overview-data-table-content .paginator{
	height: 45px;
}

.rd-overview-data-table-content .pagination {
	padding: 5px;
}

/*.rd-overview-data-sets-card-vertical .rd-overview-data-sets-card-vertical-table-wrapper {
	padding-bottom: 5px;
}*/
.admin .nav-item {
	cursor: pointer;
}

.admin .nav-link {
	color: var(--bs-primary);
}

.dark-mode .admin .nav-tabs .nav-link.active {
	background-color: var(--dark-mode-bg);
	color: #fff;
}

/* desktop layout */
@media (min-width: 901px) {
	.admin .dashboard-content {
		/*max-width: 80%;*/
	}
	.admin td.break-anywhere {
		line-break: anywhere;
	}
}

/* mobile layout */
@media (max-width: 900px) {
	.admin td.break-anywhere {
		/* disabling this on mobile since table scrolls horizontally */
		line-break: auto;
	}
}
.rd-request-documents .property-document-list {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-height: 320px;
	justify-content: flex-start;
	max-width: 300px;
}


.rd-request-documents .request-docs-lists {
	display: flex;

}


/* desktop layout */
@media (max-width: 1250px) {
	.rd-request-documents .property-document-list {
		flex-wrap: nowrap;
		flex-direction: column;
	}
}
@media (min-width: 901px) {
	.rd-request-documents .property-document-list {
		/*	flex-wrap: wrap;*/
		/*flex-direction: column;*/
	}
}



/* mobile layout */
@media (max-width: 900px) {
	.rd-request-documents .request-docs-lists {
		flex-direction: column-reverse;
	}
}
.docuemnt-request-modal-content .spinner-container {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	background: rgba(255, 255, 255, 0.9);
}

.dark-mode .docuemnt-request-modal-content .spinner-container {
	background: rgba(32, 32, 32, 0.97);
}

.docuemnt-request-modal-content .form-group {
	margin-bottom: 20px;
}

.docuemnt-request-modal-content .email-input.invalid {
	border-color: var(--bs-danger);
}

.docuemnt-request-modal-content .email-input.valid {
	border-color: var(--bs-success);
}
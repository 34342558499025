

:root {
	--aapc-color-primary: #ffdc32;
	--arkco-color-primary: #f79421;
	--az-title-color-primary: #29373b;
	--azark-color-primary: #d5e1b5;
	--bvs-color-primary: #aa182c;
	--vps-color-primary: #e91172;
}

.vendors-card a.vendor-link {
	background: white;
	color: var(--text-black);
	padding: 8px;
	margin: 5px;
/*	width: 150px;*/
	border-radius: 10px;
	max-width: 100%;
	height: 65px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	flex-direction: column;
}

/*.vendors-card .vendor-link.aapc:hover {
	color: black;
	background-color: var(--aapc-color-primary);
}
.vendors-card .vendor-link.arkco:hover {
	color: black;
	background-color: var(--arkco-color-primary);
}
.vendors-card .vendor-link.az-title:hover {
	color: white;
	background-color: var(--az-title-color-primary);
}
.vendors-card .vendor-link.azark:hover {
	color: black;
	background-color: var(--azark-color-primary);
}
.vendors-card .vendor-link.bvs:hover {
	color: black;
	background-color: var(--bvs-color-primary);
}
.vendors-card .vendor-link.vps:hover {
	color: black;
	background-color: var(--vps-color-primary);
}*/

.vendors-card a.vendor-link img {
/*	width: 20px;*/
	height: 50%;
/*	margin-right: 10px;
	position: absolute;
	right: 0;
	bottom: 10px;
	min-height: 12px;*/
}

.vendors-card a.vendor-link:not(:hover) img {
	filter: grayscale(1);
}

.vendor-links {
	display: grid;
/*	grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));*/
	grid-template-columns: repeat(3, minmax(100px, 1fr));
}



/*.vendor-links a {
	width: 45%;
}*/

.vendors-card a{
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	box-shadow: var(--box-shadow);
}

.vendors-card a:hover{
	transform: scale(1.05);
	text-decoration: none;
}


/* desktop layout */
@media (min-width: 901px) {
	
}

/* mobile layout */
@media (max-width: 900px) {

}
.owners .export-link {
	display: inline-flex;
	align-items: center;
	font-size: 22px;
	margin: 0 10px;
}


/* desktop layout */
@media (min-width: 901px) {
}

/* mobile layout */
@media (max-width: 900px) {
	.owners .export-link {
		margin-left: 10px;
	}
	.owners .card-body {
		padding-left: 0;
		padding-right: 0;
	}
	.owners .owner-name-row {
		min-width: 240px;
		padding-left: 10px;
	}
	.owners .owner-email-row {
		min-width: 200px;
	}
	.owners .owner-phone-row {
		min-width: 124px;
	}
	.owners .owner-date-row {
		min-width: 142px;
	}
	.owners .owner-source-row {
		min-width: 260px;
	}
	.owners .form-row>div.col {
		margin: 0 15px;
	}
	.owners .form-select {
		padding: 5px 10px;
		line-height: 1;
	}
}

.dashboard-portfolio {
	padding-bottom: 60px;
}
.dashboard-portfolio .portfolio-items {
	display: flex;
	flex-wrap: wrap;
}

.dashboard-portfolio .building-card-wrapper {
	margin: 30px;
}

.dashboard-portfolio .thumb-small {
	width: 40px;
	border-radius: 4px;
	cursor: pointer;
}

.dashboard-portfolio .dashboard-thumb-cell {
	width: 50px;
}

.dashboard-portfolio .dashboard-table-wrapper td {
	vertical-align: middle;
}
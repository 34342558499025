.file-upload-button {
	position: relative;
}

.file-upload-button .num-files {
	position: absolute;
	background: #1d9ff7;
	color: white;
	border-radius: 14px;
	min-width: 15px;
/*	height: 14px;*/
	font-size: 10px;
	left: 15px;
/*	top: 1px;*/
	padding: 1px 4px 0px 4px;
}
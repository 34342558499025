.mobile-search-page {
	text-align: center;
	text-align: center;
	margin: 20px;

}

.mobile-search-page div {
	margin-bottom: 20px;	
}

.mobile-search-page h2 {
	font-size: 16px;
	margin-bottom: 20px;	
}

.mobile-search-page .divider {
	border-bottom: 1px solid #ced4da;
	width: calc(100% * .67);
	margin: 20px auto;
}
.rd-dashboard {
	display: flex;
	height: 100%;
	background-color: #ccc;
/*	background-color: var(--rd-black);*/
/*	font-size: 20px;*/
}

.rd-dashboard .rd-dashboard-content-wrapper {
/*	padding: 20px;*/
/*	background-color: var(--rd-grey);*/
/*	border-top-left-radius: 50px;*/
	width: calc(100% - 220px);
/*	overflow: auto;*/
	max-height: calc(100% - 56px);
	background-color: #fff;

	-webkit-transition: width 0.4s ease-in-out;
	-moz-transition: width 0.4s ease-in-out;
	-o-transition: width 0.4s ease-in-out;
	transition: width 0.4s ease-in-out;
}


.rd-dashboard .rd-dashboard-content-wrapper.is-collapsed {
	width: calc(100% - 50px);
}

.rd-dashboard .rd-dashboard-content {
	padding: 20px;
/*	background-color: var(--rd-grey);*/
	border-top-left-radius: 40px;
	width: 100%;
	height: 100%;
	background-color: #ccc;
	overflow: auto;
/*	width: calc(100% - 220px);
	overflow: auto;
	max-height: calc(100% - 56px);*/
}

.rd-dashboard h1 {
/*	color: var(--rd-green);*/
/*	padding: 10px;*/
	font-size: 24px;
}

.rd-dashboard .paginator,
.rd-dashboard .pagination {
	background-color: transparent;
}

.rd-dashboard .card,
.dark-mode .rd-dashboard .card .card-header,
.dark-mode .rd-dashboard .card {
	border: none;
	background-color: #fff;
}


.dark-mode .rd-dashboard .table-striped th,
.rd-dashboard .table-striped th {
  background-color: transparent;
}
.dark-mode .rd-dashboard .table-striped>tbody>tr:nth-of-type(odd),
.rd-dashboard .table-striped>tbody>tr:nth-of-type(odd) {
  background-color: #fff;
}
.dark-mode .rd-dashboard .table-striped>tbody>tr:nth-of-type(even),
.rd-dashboard .table-striped>tbody>tr:nth-of-type(even) {
  background-color: #ddd;
}


/* desktop layout */
@media (min-width: 1231px) {
	.rd-dashboard {
		
	}
}

/* mobile layout */
@media (max-width: 1230px) {
	.rd-dashboard .rd-dashboard-content-wrapper {
		width: calc(100% - 50px);
		padding: 0;
	}

	.rd-dashboard .rd-dashboard-content {
		padding: 5px;
		padding-top: 10px;
		border-top-left-radius: 0px;
		overflow: auto;
	}

	.rd-dashboard h1 {
		margin: 10px;
	}
}
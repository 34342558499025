
/* desktop layout */
@media (min-width: 901px) {
}

/* mobile layout */
@media (max-width: 900px) {
	.record-details-table .key-cell {
		max-width: calc(40vw);
	}
}

.images-widget {
	position: relative;
	height: 100%;
}

.images-widget .main-image {
	width: 400px;
	height: 348px;
	padding: 10px;

}

.images-widget .street-view-image {
	border-radius: 10px;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.images-widget .thumbnail-carousel {
	display: flex;
	width: 100%;
	overflow-x: auto;
	padding: 0 10px;

}

.images-widget .thumbnail-carousel .thumb-image {
	width: 92px;
	height: 92px;
	margin: 4px;
	border-radius: 10px;
	cursor: pointer;
}

.images-widget .image-overlay-buttons {
	position: absolute;
	right: 10px;
	top: 10px;
	display: flex;
	flex-direction: column;
	padding: 10px;

}

.images-widget .image-overlay-buttons button {
	border: none;
	font-size: 14px;
	color: white;
	background-color: var(--dark-mode-bg);
	border-radius: 100%;
	width: 30px;
	height: 30px;
	padding-top: 2px;
	margin-bottom: 5px;
}

.dark-mode .images-widget .image-overlay-buttons button:hover,
.images-widget .image-overlay-buttons button:hover {
	color: var(--bs-primary);
}

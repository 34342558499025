.my-documents-page {
	max-height: calc(100% - var(--app-header-height));
	overflow: auto;
}

.my-documents-page .upload-wrapper {
	padding: 10px;
	border: 1px solid var(--border-color);
	border-radius: var(--border-radius);
}


@media (min-width: 901px) {
	.my-documents-page .card {
		margin: 20px;
	}
}

/* mobile layout */
@media (max-width: 900px) {
	.my-documents-page .card-body {
		padding: 0;
	}

	.files-widget .upload-title {
		margin-left: 10px;
	}
}
.spinner-overlay .spinner-text {
	font-size: 24px;
	margin-top: 20px;
}

.spinner-overlay {
	width: 100%;
	height: 100%;
	position: fixed;
	background: rgba(255, 255, 255, 0.9);
	z-index: 1000;
}

.dark-mode .spinner-overlay {
	background: rgba(32, 32, 32, 0.97);
}

.spinner-overlay .spinner-border {
	width: 45px;
	height: 45px;
	font-size: 22px;
}

.spinner-overlay .spinner-container {
	align-items: baseline;
	margin-top: calc(100vh * .2);
}

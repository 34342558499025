.use-policy-container {
	max-height: 100%;
	overflow-y: auto;
}

.use-policy {
	max-width: 800px;
	margin: auto;
	margin-top: 60px;
	margin-bottom: 120px;
}

.use-policy h1 {
	font-size: 1.5rem;
}

.use-policy h2 {
	font-size: 1.2rem;
}

.use-policy h3 {
	font-size: 1.1rem;
}
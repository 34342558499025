.vertical-button-group {
	transform: rotate(90deg);
	transform-origin: bottom left;
	flex-direction: row;
	justify-content: space-between;
	display: flex;
	width: 454px;
	border-radius: 10px;
	margin-top: -40px;
	height: 40px;

	background-color: var(--dark-mode-bg-2);
	box-shadow: var(--box-shadow-vertical);
}

.vertical-button-group button {
	background-color: var(--border-color);
	color: var(--app-black);
	white-space: nowrap;
	border-radius: 10px;
	margin: 0 5px;
	flex-grow: 1;
	transform: rotate(180deg);
	font-size: 14px;
	height: 40px;
	-moz-transition: all .2s ease-in;
	-o-transition: all .2s ease-in;
	-webkit-transition: all .2s ease-in;
	transition: all .2s ease-in;
}

.vertical-button-group button span {
	color: var(--dark-text-color);
	-moz-transition: all .2s ease-in;
	-o-transition: all .2s ease-in;
	-webkit-transition: all .2s ease-in;
	transition: all .2s ease-in;

}

.vertical-button-group button:hover span{
	color: var(--bs-white);
}

.vertical-button-group button:first-of-type {
	margin-left: 0;
}

.vertical-button-group button:last-of-type {
	margin-right: 0;
}

.dark-mode .vertical-button-group button.is-selected,
.dark-mode .vertical-button-group button:hover,
.vertical-button-group button:hover {

	/*background color changes just fine. font color does not*/
	background-color: var(--bs-primary);
	color: var(--bs-white);
}

.dark-mode .vertical-button-group button {
	background-color: var(--dark-mode-bg-2);
	color: var(--dark-mode-color);
}

.vertical-button-group button.is-selected span{
	color: var(--bs-white);

}

@media (max-width: 1230px) {
	.vertical-button-group {
		margin-top: -30px;
	}
}



.rd-overview-building-card .rd-tab-card-content {
	height: 400px;
	font-size: 14px;
}

.rd-overview-building-card h2 {
	font-size: 22px;
	/*	color: var(--rd-green);*/
	font-weight: bold;
	margin-bottom: 10px;
	/*	border-bottom: 1px solid var(--rd-green);*/
	border-bottom: 1px solid var(--text-black);
}

.dark-mode .rd-overview-building-card h2 {
	border-bottom: 1px solid var(--dark-mode-color);
}

.rd-overview-building-card .property-document-list {
	max-width: 100%;
	width: 100%;
}

.rd-overview-building-card{
	height: 100%;
}
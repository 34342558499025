.paginator {
	/*background-color: white;*/
}

.dark-mode .paginator,
.dark-mode .paginator .page-link,
.dark-mode .paginator .pagination {
	/*background-color: var(--dark-mode-bg);*/
	color: var(--dark-mode-color);
}

.pagination .page-item:not(.disabled) {
	cursor: pointer;
}

.pagination {
	/*background-color: #fff;*/
	/*border-top:  1px solid #d6d6d6;*/
/*	border-top: 1px solid var(--border-color);*/
	padding: 15px 20px 20px 20px;
	overflow-x: auto;
	margin-bottom: 0;
	justify-content: center;
}

.pagination .page-item.active .page-link {
	background-color: var(--bs-primary);
	border-color: var(--bs-primary);
	color: white;
}
.pagination .page-link {
	color: var(--bs-primary);
	transition: none;
}
.pagination .page-link:hover {
	color: white;
	background-color: var(--bs-primary);
	border-color: var(--bs-primary);
}

.pagination .page-item.page-ellipsis:not(disabled),
.pagination .page-item.page-ellipsis {
	padding: 0.375rem 0.75rem;
	cursor: default;
}


/* desktop layout */
@media (min-width: 901px) {
	.pagination {
		/*max-width: calc(100% - 140px);*/
	}
}

/* mobile layout */
@media (max-width: 900px) {
	.pagination {
		margin-bottom: 60px;
		/*		max-width: calc(100% - 80px);*/
	}
}

:root {
	--border-color: #d6d6d6;
	--text-black: #212529;
	--app-black: #212529;
	--app-header-height: 56px;
	--app-toolbar-height: 56px;

	/*	--dark-mode-bg: #282c34;*/
	--dark-mode-bg: #FFFFFF;
	--dark-mode-bg-2: #DDDDDD;
	--dark-mode-page-bg: #CCCCCC;
	--dark-mode-color: #355E3B;
	/*	--dark-mode-color: #e8e7f5;*/
	--dark-mode-border-color: var(--dark-mode-bg-2);

	--light-bg-color: #eff2f5;

	--text-color: #355E3B;
	--dark-text-color: #444444;
	--box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.25);
	--box-shadow-secondary: 4px 4px 17px 2px rgba(0, 0, 0, 0.25);
	--box-shadow-vertical: 4px -4px 5px 0px rgba(0, 0, 0, 0.25);

}

html {
	height: 100%;
}

body {
	--bs-body-font-size: 14px;
	/*	font-family: 'Poppins', sans-serif;*/
	/*	font-family: 'Questrial', sans-serif;*/
	font-family: 'Gothic A1', sans-serif;
	background-color: var(--light-bg-color);
	font-size: 13px;
}

body.dark-mode {
	--border-color: var(--dark-mode-border-color);
	background-color: var(--dark-mode-bg);
	color: var(--dark-mode-color);
}
.dark-mode .modal-content,
.dark-mode .card .card-header,
.dark-mode .card {
	background-color: var(--dark-mode-bg-2);
	color: var(--dark-mode-color);
}
.dark-mode .card {
	border: 1px solid var(--dark-mode-border-color);
}
.dark-mode .table {
	color: var(--dark-mode-color);
}
.dark-mode .table-striped>tbody>tr:nth-of-type(odd) {
	background-color: var(--dark-mode-bg-2);
	color: var(--dark-mode-color);
}
/*.dark-mode button:not(.btn-light):not(.btn-hyperlink) {
	color: var(--bs-white);
}*/
.dark-mode .navbar.bg-light {
	background-color: var(--dark-mode-bg) !important;
	color: var(--dark-mode-color);
}
.dark-mode .navbar .navbar-brand,
.dark-mode .navbar-light .navbar-text {
	color: var(--dark-mode-color);
}

.dark-mode .nav-tabs .nav-item.show .nav-link,
.dark-mode .nav-tabs .nav-link.active {
	background-color: transparent;
	color: var(--dark-mode-color);
}
.dark-mode .nav-link {
	color: var(--bs-primary);
}


body, #root, .app {
	height: 100%;
	overflow: hidden;
}

.link-disabled {
	color: var(--bs-gray-600);
	cursor: default;
}

a {
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

a.btn:hover {
	text-decoration: none;
}

.btn:focus, .btn:active:focus, .form-control:focus  {
	box-shadow: none;
}

.btn, .form-control {
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

/* btn-light:hover built in style seems to be broken in bootstrap, not sure why. recreate manually: */
.btn-light:hover {
	background-color: #e2e6ea;
	border-color: #dae0e5;
}

button.btn-hyperlink {
	background: transparent;
	border: none;
	font-weight: normal;
	/*color: #0d6efd;*/
	color: var(--bs-primary);
	padding: 0;
}

button.btn-hyperlink:hover {
	/*color: #0a58ca;*/
	color: var(--bs-active-primary);
	text-decoration: underline;
}

button.btn-hyperlink:disabled {
	color: var(--bs-gray-600);
	cursor: default;
}

button.btn-hyperlink:disabled:hover {
	color: var(--bs-gray-600);
	text-decoration: none;
}

.text-left {
	text-align: left;
}

.__react_component_tooltip.show {
	opacity: 1 !important;
}

.small-text {
	font-size: 12px;
	color: var(--bs-gray-600);
}

.linkstyle {

}

.linkstyle:hover {

}

table.table.table-rounded {
	border-radius: 10px;
	border-collapse: separate;
	border-spacing: 0 3px;
}

table.table.table-rounded tr {
	border-radius: 10px;
}

table.table.table-rounded th {
  padding-bottom: 2px;
}
table.table.table-rounded th,
table.table.table-rounded td {
	border: none;
	box-shadow: none;
}

table.table.table-rounded tr td:first-child,
table.table.table-rounded tr th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
 
table.table.table-rounded tr td:last-child,   
table.table.table-rounded tr th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #444444;
	border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	/*	background: #20DF92;*/
	background: var(--bs-primary);
	border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	/*	background: #13925E; */
	background: var(--bs-active-primary);
}

::-webkit-scrollbar-thumb:active {
	/*	background: #116A46; */
	background: var(--bs-active-primary);
}


/* desktop layout */
@media (min-width: 901px) {
}

/* mobile layout */
@media (max-width: 900px) {
	.card-body {
		overflow-x: auto;
	}

	body iframe#tidio-chat-iframe {
		inset: auto 10px 10px auto !important;
	}
}
.property-notification-modal-content .spinner-container {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	background: rgba(255, 255, 255, 0.9);
}
.dark-mode .property-notification-modal-content .spinner-container {
	background: rgba(32, 32, 32, 0.97);
}

.property-notification-modal-content  {
	height: 60vh;
	overflow-y: scroll;
}

.auth-page {
	max-height: 100%;
	overflow: auto;
}

.auth-page-content {
	margin: auto;
	margin-top: 40px;
	max-width: 400px;
	margin-bottom: 60px;
}


.auth-page-content h1 {
	margin-bottom: 40px;
}

.auth-page .input-wrapper {
	margin-bottom: 10px;
}

.auth-page .btn {
	margin: 20px 0;
}


/* desktop layout */
@media (min-width: 901px) {
}

/* mobile layout */
@media (max-width: 900px) {
	.auth-page {
		margin: 20px;
	}

	.auth-page-content {
		margin-top: 0;
	}
	.auth-page-content h1 {
		margin-bottom: 20px;
	}
}